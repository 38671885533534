import Snack from "@/mixins/Snack.js";

const TableMixin = {
	mixins: [Snack],
	data: () => ({
		expanded: [],
		search: null,
		loading: true,
		filter: {date: null}
	}),
	computed: {
		headers() {
			const actions = [{text: "Actions", value: "actions", sortable: false}]
			if (this.$helper.isAdmin()) return actions.concat(this.headings)
			else return this.headings
		},
	},
	watch: {
		"filter.date": {
			async handler() {
				await this.dateFilter()
			}
		}
	},
	async created () {
		await this.initialize()
	},
	methods: {
		async initialize() {
			this.loading = true
			this.filter.date = null
			const fetchUrl = this.url || this.$urls[this.model].list
			try {
				const response = await this.$axios.get(fetchUrl)
				this.SET_LIST(response)
			} catch {
				await this.openSnack({text: `${this.$helper.capitalize(this.model)} loading failed.`})
			} finally {
				this.loading = false
			}
		},
		async initializeFilter() {
			this.loading = true
			try {
				const response = await this.$axios.getWithPayload(this.$urls[this.model].list, this.filter)
				this.SET_LIST(response)
			} catch {
				await this.openSnack({text: `${this.$helper.capitalize(this.model)} loading failed.`})
			} finally {
				this.loading = false
			}
		},
		async nextprev(url) {
			this.loading = true
			this.filter.date = null
			const fetchUrl = url || this.$urls[this.model].list
			try {
				const response = await this.$axios.get(fetchUrl)
				this.SET_LIST(response)
			} catch {
				await this.openSnack({text: `${this.$helper.capitalize(this.model)} loading failed.`})
			} finally {
				this.loading = false
			}
		},
		async dateFilter() {
			if(this.filter.date) {
				await this.initializeFilter()
			}
		},
		editItem (item) {
			this.SET_FOR_EDIT(item)
			this.SET_DIALOG_STATE(true)
		},
		deleteItem (item) {
			this.SET_ITEM_TO_DELETE({id: item.id, model: this.model})
		},
		initCreate() {
			this.SET_FOR_EDIT(null)
			this.SET_DIALOG_STATE(true)
			this.SET_FORM_ERRORS(null)
		}
	},

}

export default TableMixin
