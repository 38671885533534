<template>
	<div class="pa-4">
		<v-data-table :search="search" :loading="loading" :headers="headers" :items="attendances.results"
			:single-expand="true" :expanded.sync="expanded" show-expand hide-default-footer class="elevation-4 rounded"
			loading-text="Loading attendances">
			<template #top>
				<v-toolbar flat height="auto" class="rounded-t" color="#ECEFF1">
					<v-toolbar-title class="table-toolbar-title pr-2" :class="{ 'px22': (title) }">
						{{ title || $route.name }}
					</v-toolbar-title>

					<v-responsive max-width="400" height="100%" class="pa-2"
						content-class="d-flex justify-center align-center">
						<text-field icon="mdi-magnify" v-model="search" name="search" label="Search attendance"
							class="full-width" :dense="true" />
					</v-responsive>
					<v-spacer />
					<v-responsive v-if="!url" max-width="350" height="100%" class="pa-2"
						content-class="d-flex justify-center align-center">
						<date-picker v-model="filter.date" label="Filter Date" name="date" icon="mdi-calendar"
							class="full-width" :dense="true" picker="DATE" />
						<v-fab-transition>
							<v-btn v-if="filter.date" icon @click="initialize"
								class="mx-1"><v-icon>mdi-close</v-icon></v-btn>
						</v-fab-transition>
					</v-responsive>
					<v-btn v-if="!title" color="primary lighten-1" @click="initCreate">
						<v-icon left>mdi-plus-circle</v-icon>
						New
					</v-btn>
				</v-toolbar>
			</template>
			<template v-slot:expanded-item="{ headers, item }">
				<td :colspan="headers.length">
					<v-row v-if="item" class="ma-0 pa-0" no-gutters>
						<v-col cols="12" class="pa-2 pb-0">More information about <span class="primary--text">#{{ item.id
						}}</span></v-col>
						<v-col cols="12" xl="6" lg="6" md="6" sm="6" class="pa-2">
							<div class="d-flex align-center py-2">
								<v-icon class="mr-2">mdi-map-marker-plus</v-icon>
								<div>
									Clock In Location
								</div>
								<div class="px-1" v-if="item.clock_in_location_user_input">:</div>
								<div v-if="item.clock_in_location_user_input" class="font-bold">
									{{ item.clock_in_location_user_input }}
								</div>
							</div>
							<v-card v-if="item.clock_in_location" max-width="350" class="my-2">
								<location-preview v-if="item.clock_in_location.startsWith('Latitude')"
									:lat-lng="getItemLatLng(item.clock_in_location)" />
								<v-card-title v-else>{{ item.clock_in_location }}</v-card-title>
							</v-card>
							<div v-else class="px-4">-</div>
						</v-col>
						<v-col cols="12" xl="6" lg="6" md="6" sm="6" class="pa-2">
							<div class="d-flex align-center py-2">
								<v-icon class="mr-2">mdi-map-marker-minus</v-icon>
								<div>
									Clock Out Location
								</div>
								<div class="px-1" v-if="item.clock_out_location_user_input">:</div>
								<div v-if="item.clock_out_location_user_input" class="font-bold">
									{{ item.clock_out_location_user_input }}
								</div>
							</div>
							<v-card max-width="350" class="my-2" v-if="item.clock_out_location">
								<location-preview v-if="item.clock_out_location.startsWith('Latitude')"
									:lat-lng="getItemLatLng(item.clock_out_location)" />
								<v-card-title v-else>{{ item.clock_out_location }}</v-card-title>
							</v-card>
							<div v-else class="px-4">-</div>
						</v-col>
						<v-col cols="12">
							<divider height="2" />
						</v-col>
						<v-col>
							<list-item icon="mdi-message" subtitle="Remarks" :title="(item.remarks) || '-'" />
						</v-col>
						<v-col>
							<list-item icon="mdi-clock" subtitle="Working Hours" :title="calculateWorkingHour(item)" />
						</v-col>
						<v-col>
							<list-item icon="mdi-projector-screen" subtitle="Working On"
								:title="(item.working_on) ? item.working_on.name : '-'" />
						</v-col>
					</v-row>
				</td>
			</template>
			<template #item.date="{ item }">
				{{ $formatDate(item.date) }}
			</template>
			<template #item.clock_in="{ item }">
				{{ $unixTime(item.clock_in) }}
			</template>
			<template #item.clock_out="{ item }">
				{{ $unixTime(item.clock_out) }}
			</template>
			<template #item.actions="{ item }">
				<tooltip-btn icon="mdi-pencil" color="primary lighten-1" tooltip="Edit Attendance"
					@click="editItem(item)" />
				<tooltip-btn color="error lighten-1" icon="mdi-delete" tooltip="Delete Record" @click="deleteItem(item)" />
			</template>
			<template #no-data>
				<v-btn color="primary" @click="initialize">
					Reset
				</v-btn>
			</template>
		</v-data-table>
		<attendance-dialog @init="initialize" />
		<delete-dialog :model="model" @init="initialize" />
		<div class="my-2 d-flex paginate-btn-group">
			<v-btn class="paginate-btn" v-if="attendances.previous" @click="changePage(attendances.previous)">Previous</v-btn>
			<v-btn class="paginate-btn" v-if="attendances.next" @click="changePage(attendances.next)">Next</v-btn>
		</div>
	</div>
</template>

<script>
import { latLng } from "leaflet"
import { mapGetters, mapMutations } from "vuex";
import TableMixin from "@/mixins/TableMixin.js";


export default {
	name: "Attendance",
	props: {
		url: {
			required: false,
			type: String
		},
		title: {
			required: false,
			type: String
		}
	},
	components: {
		AttendanceDialog: () => import("@/components/Dialogs/AttendanceCUDialog"),
		LocationPreview: () => import("@/components/LocationPreview.vue")
	},
	mixins: [TableMixin],
	data: () => ({
		model: "attendance",
		headings: [
			{ text: "Staff", value: "user_name" },
			{ text: "Date", value: "date", sortable: true },
			{ text: "Clock In", value: "clock_in", sortable: true },
			{ text: "Clock Out", value: "clock_out" },
			{ text: "", value: "data-table-expand" },
		],
	}),
	computed: {
		...mapGetters({
			"attendances": "attendance/list",
			"currentUser": "currentUser",
		})
	},
	methods: {
		...mapMutations("attendance", ["SET_LIST", "SET_FOR_EDIT", "SET_DIALOG_STATE", "SET_FORM_ERRORS"]),
		...mapMutations("toDelete", ["SET_ITEM_TO_DELETE"]),
		getItemLatLng(item) {
			if (item) {
				const position = this.$helper.getLatLng(item)
				if (position) return latLng(position.latitude, position.longitude)
				return null
			} else return null
		},
		calculateWorkingHour(item) {
			return (item.working_hour)
				? (item.working_hour / 3600).toFixed(2).toString()
				: "-"
		},
		async changePage(url) {
			console.log(url)
			await this.nextprev(url);
		},
	},
}
</script>
<style lang="scss">
@import "src/styles/fonts";

.paginate-btn{
	background: #fff !important;
}
</style>
